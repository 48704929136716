<template>
  <div class="index">
    <div class="box">
      <div class="left" style="position: relative">
        <div class="top">
          <!--                    //这个盒子的意义是防止抖动-->
          <div class="top-in">课程大纲</div>
        </div>
        <!--                这个盒子是用来占位置的-->
        <div class="empty" />
        <div class="custom-tree-container">
          <div class="block">
            <el-tree
              ref="ProjectTree"
              class="filter-tree"
              :data="data"
              :props="defaultProps"
              icon-class="el-icon-arrow-right"
              highlight-current
              :expand-on-click-node="true"
              @node-click="handleNodeClick"
              @node-expand="handleNodeClick"
            >
              <span slot-scope="{ node, data }" class="custom-tree-node" @click="clickLock(data)">
                <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start" :disabled="!showTip">
                  <span :class="{'topTree':data.parentId===0,'section':data.type===1}" style="font-size: 16px" @mouseover="textRange"> {{ node.label }}</span>
                </el-tooltip>
                <span>
                  <!--                                四种状态，自己写v-if对应的状态就行-->
                  <el-button v-if="data.completeStatus===0&&data.type===1" type="text" size="mini" style="color: #999999">
                    <i class="el-icon-lock" />
                  </el-button>
                  <!--                                <el-button type="text" size="mini" v-if="data.completeStatus===0&&data.type===1" style="color: #999999"> <span class="state some">基</span> </el-button>-->
                  <!--                                <el-button type="text" size="mini" v-if="data.completeStatus===0&&data.type===1" style="color: #999999"><span class="state little">概</span> </el-button>-->
                  <!--                                <el-button type="text" size="mini" v-if="data.completeStatus===0&&data.type===1" style="color: #999999"><span class="state no">未</span> </el-button>-->
                  <el-dropdown v-if="data.completeStatus!==0&&data.type===1" placement="right" @command="command($event,data,node)">
                    <span class="el-dropdown-link" style="width: 100%">
                      <!--                                    <i class="el-icon-arrow-down el-icon&#45;&#45;right" ></i>-->
                      <el-button v-if="data.studyStatus=='0'||data.studyStatus==null" type="text" size="mini" style="color: #999999"> <span class="state some">未</span> </el-button>
                      <el-button v-if="data.studyStatus=='1'" type="text" size="mini" style="color: #999999"><span class="state little">基</span> </el-button>
                      <el-button v-if="data.studyStatus=='2'" type="text" size="mini" style="color: #999999"><span class="state no">概</span> </el-button>
                      <el-button v-if="data.studyStatus=='3'" type="text" size="mini" style="color: #999999"><span class="state no">完</span> </el-button>

                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">
                        <el-button type="text" size="mini" style="color: #999999"> <span class="state some">未</span> </el-button>
                                    &nbsp;<span>未学习</span></el-dropdown-item>
                      <el-dropdown-item command="1">
                        <el-button type="text" size="mini" style="color: #999999"><span class="state little">基</span> </el-button>
                        &nbsp;<span>基本掌握</span></el-dropdown-item>
                      <el-dropdown-item command="2">
                        <el-button type="text" size="mini" style="color: #999999"><span class="state no">概</span> </el-button>
                        &nbsp;<span>大概了解</span></el-dropdown-item>
                      <el-dropdown-item command="3">
                        <el-button type="text" size="mini" style="color: #999999"><span class="state no">完</span> </el-button>
                        &nbsp;<span>完全掌握</span></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button v-if="data.completeStatus===0&&data.type===1" type="text" size="mini" style="color: #999999"> <span class="state some">未</span> </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tab">
          <router-link to="/videoPage">
            <div :class="{'select':this.$route.path.includes('/videoPage')}"><i
              class="el-icon-video-play"
              style="padding-right: 5px"
            />视频
            </div>
          </router-link>
          <router-link to="/material">
            <div :class="{'select':this.$route.path.includes('/material')}"><i
              class="el-icon-document"
              style="padding-right: 5px"
            />资料
            </div>
          </router-link>
          <router-link to="/problem">
            <div :class="{'select':this.$route.path.includes('/problem')}"><i
              class="el-icon-edit-outline"
              style="padding-right: 5px"
            />习题集
            </div>
          </router-link>

        </div>
        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import { classTreeList, changeStudyStutus } from '../../api/study'

import { Message } from 'element-ui'
export default {
  name: 'Index',
  components: { indexBottom },
  data() {
    return {
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
        // disabled: function(data) {
        //   if (data.type === 1) {
        //     return !data.leaf
        //   }
        // }
      },
      showTip: false
    }
  },
  created() {
    this.getTree()
  },
  methods: {
    // 点击锁
    clickLock(status) {
      if (status.completeStatus === 0 && status.type === 1) {
        Message.warning('该小节尚未解锁')
      }
    },
    getTree() {
      this.loading = this.$loading({
        lock: true,
        text: '请稍等',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      classTreeList({ classId: this.$store.state.user.userInfo.classId, mark: 0 }).then(res => {
        if (res.code === 200) {
          this.data = res.rows
          console.log('请求回来的课程信息', this.data)
          this.loading.close()
        }
      })
    },
    handleNodeClick(node, data) {
      console.log(node, data)
      if (node.completeStatus == 0 && node.type === 1) {
        data.expanded = false
      }
      if (node.completeStatus != 0 || node.type === 0) {
        this.$store.commit('SET_NODE', node)
      }
    },
    command(event, data) {
      console.log(data)
      this.$set(data, 'studyStatus', event)
      changeStudyStutus({ classOutlineId: data.id, studyStatus: event }).then(res => {
        if (res.code === 200) {
          console.log(res)
          this.$message.success('修改成功')
        }
      })
    },
    textRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showTip = rangeWidth > targetW
    }

  }
}
</script>

<style scoped lang="less">

    ::-webkit-scrollbar {/*滚动条整体样式*/
        width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
        /*height: 24px;*/
        /*scrollbar-arrow-color:red;*/
    }
    /*::-webkit-scrollbar-thumb {!*滚动条里面小方块*!*/
    /*    border-radius: 5px;*/
    /*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*    background: rgba(0,0,0,0.2);*/
    /*    scrollbar-arrow-color:red;*/
    /*}*/
    /*::-webkit-scrollbar-track {!*滚动条里面轨道*!*/
    /*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*    border-radius: 0;*/
    /*    background: rgba(0,0,0,0.1);*/
    /*}*/
 /*未学习 大概了解 基本掌握 完全掌握 */
 /*课程大纲标识*/
 .state{
     position: relative;
     color: white;
     display: inline-block;
     padding: 4px;
     border-radius: 50%;
 }
 .all{
     background: rgba(255, 87, 51, 1);
 }
 .some{
     background: rgba(255, 141, 26, 1);
 }
 .little{
     background: rgba(121, 72, 234, 1);
 }
 .no{
     background: rgba(0, 177, 181, 1);
 }
 .top-in{
     padding: 20px 0;
 }

    ::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
        background:rgba(57, 165, 239, .3);
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #39A5EF !important;
        line-height: 18px;

        .section,.topTree{
            color: #39A5EF !important;
        }
    }
    .topTree {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }

    .section {
        color: #999999;
      width: 79%;
      overflow: hidden;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .select {
        background: white !important;
        color: #2c3e50 !important;
        font-size: 16px !important;
    }

    .index {
        margin-top: 80px;

        .box {
            display: flex;
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 20px;

            > .left {
                width: 410px;
                padding: 0 20px;
                /*height: 720px;*/
                background: #FFFFFF;

                ::v-deep.el-tree{
                    .el-tree-node__content{
                        height: 35px;
                    }
                  .el-tree-node__children {
                    .el-tree-node__content{

                      .custom-tree-node{
                        >span:first-child{
                          width: 200px;
                          text-align: left;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        }
                        //>span:nth-child(2){
                        //  width: 20%;
                        //
                        //}
                      }
                    }
                  }

                }

                .top {
                    position: absolute;
                    width: 100%;
                    left :0;
                    height: 42px;
                    /*background: #39A5EF;*/
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    /*color: #FFFFFF;*/
                    line-height: 42px;
                }
            }
            .empty {
                height: 80px;
            }
            .custom-tree-container {
                overflow: hidden!important;
                overflow: scroll!important;
                overflow: auto!important;
                height: 680px;
            }
            > .right {

                > .tab {
                    display: flex;
                    margin-top: 20px;
                    /*justify-content: space-evenly;*/

                    div {
                        margin-left: 30px;
                        width: 110px;
                        height: 30px;
                        background: #FFFFFF;
                        border-radius: 15px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 30px;
                    }

                    div:hover {
                        cursor: pointer;
                        background: #39A5EF;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;

        }
    }

    ::v-deep .el-tree-node__expand-icon {
        position: absolute;
        right: 2%;
    }

    ::v-deep.custom-tree-node {
        padding-right: 38px !important;
        padding-left: 20px;
    }

</style>
